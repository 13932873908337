import '../scss/plugins/_swiper.scss'

import Swiper from 'swiper'
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules'

const testimonials = new Swiper('[data-swiper-testimonials]', {
	modules: [Navigation, Pagination, EffectFade, Autoplay],
	autoplay: {
		delay: 4500,
		pauseOnMouseEnter: true,
	},
	loop: true,
	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},
	grabCursor: true,
	spaceBetween: 24,
	slidesPerView: 1,
	navigation: {
		nextEl: '[data-swiper-button-next]',
		prevEl: '[data-swiper-button-prev]',
	},
})
document.querySelectorAll('[data-swiper-posts-container]').forEach(container => {
	const posts = new Swiper(container.querySelector('[data-swiper-posts]'), {
		modules: [Navigation, Pagination],
		grabCursor: true,
		loop: true,
		spaceBetween: 24,
		slidesPerView: 1,
		navigation: {
			nextEl: container.querySelector('[data-swiper-button-next]'),
			prevEl: container.querySelector('[data-swiper-button-prev]'),
		},
		breakpoints: {
			667: {
				slidesPerView: 2,
			},
			992: {
				slidesPerView: 3,
			},
		},
	})
})
const sliderGalleries = document.querySelectorAll('[data-swiper-gallery]')
if (sliderGalleries.length) {
	sliderGalleries.forEach(gallery => {
		const slidesPerView = Number(gallery.getAttribute('data-slides-per-view'))
		let slidesPerViewTablet = 1
		if (slidesPerView >= 3) {
			slidesPerViewTablet = Math.round(slidesPerView / 2)
		}
		const slidesPerViewMobile = slidesPerView > 4 ? 2 : 1
		const sliderGallery = new Swiper(gallery, {
			modules: [Navigation, Pagination],
			slidesPerView: slidesPerViewMobile,
			spaceBetween: 14,
			autoHeight: true,
			loop: true,
			navigation: {
				nextEl: '[data-swiper-button-next]',
				prevEl: '[data-swiper-button-prev]',
			},
			breakpoints: {
				667: {
					slidesPerView: slidesPerViewTablet,
				},
				992: {
					slidesPerView: slidesPerView,
				},
			},
		})
	})
}
